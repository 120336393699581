import { SideBarRoute } from '@/components/Sidebar/Sidebar';
import { RouteRoot } from '@/routes/routes.model';
import { useFeatureFlagsStore } from '@/store/v2';
import { isLockedByRole, isLockedForZonesoft, userHasAnyMatchingRole } from '@/utils/roles';
import { isSuperUser, isSuperUserLocked } from '@/utils/superUser';
import { ChartUpIcon, HomeIcon, PleezIcon, TargetIcon } from '@pleez-tech/fe-components-library';

export const SIDEBAR_GROUPS = [
  {
    root: RouteRoot.Home,
    icon: HomeIcon,
  },
  {
    root: RouteRoot.Actions,
    icon: PleezIcon,
  },
  {
    root: RouteRoot.Market,
    icon: ChartUpIcon,
  },
  {
    root: RouteRoot.Analytics,
    icon: TargetIcon,
  },
];

const hasFeatureFlag = (link: SideBarRoute) => {
  if (!('requiresFlag' in link)) {
    return true;
  }

  const featureFlagStore = useFeatureFlagsStore();

  return featureFlagStore.featureFlags?.some?.((ff) => ff.name == link.requiresFlag);
};

export const routeIsDisabled = (route: SideBarRoute) => {
  if (!route.allowedRoles) {
    return false;
  }

  return !userHasAnyMatchingRole(route.allowedRoles);
};

export const canViewLink = (link: SideBarRoute) => {
  if (link.hidden) {
    // Hide if meta.hidden is true
    return false;
  }

  if (isSuperUser()) {
    // Superuser should be able to view everything
    return true;
  }

  if (!hasFeatureFlag(link)) {
    // Hide if user does not have feature flag
    return false;
  }

  if (isSuperUserLocked(link)) {
    // Hide if locked for super user
    return false;
  }

  if (isLockedForZonesoft(link)) {
    // Hide if locked for zonesoft
    return false;
  }

  if (isLockedByRole(link)) {
    // Hide if locked by role
    return false;
  }

  // User is not sudo, so hide sudo-only features
  return !link.isSuperUserLocked;
};
