<template>
  <header class="header">
    <div v-if="isMobile" class="header__temp-mobile">
      <MenuIcon class="header__mobile-menu-icon" @click="handleToggleSidebar" />
      <router-link class="header__logo" to="/home">
        <PleezLogo class="header__logo" />
      </router-link>
    </div>
    <div class="header__actions">
      <Select
        class="header__button header__account-button"
        :values="accountDropdownOptions"
        :label="authStore.email!"
        :has-search="false"
        :is-selectable="false"
        :is-clearable="false"
        @change="handleAccountOptionClick"
      >
        <div class="header__action-icon">
          <ProfileIcon />
        </div>

        <template #custom-label="{ value }">
          <div
            class="header__account-option"
            :class="{
              'header__account-option--small': value.label.length >= 25,
            }"
          >
            <component :is="value.icon" class="header__option-icon" />
            <p>{{ $t(value.label) }}</p>
          </div>
        </template>

        <template #custom-footer>
          <span class="header__flags">
            <span class="fi fi-pt" @click="setupLanguage('pt-PT')"></span>
            <span class="fi fi-gb" @click="setupLanguage('en-GB')"></span>
            <span class="fi fi-es" @click="setupLanguage('es-ES')"></span>
          </span>
        </template>
      </Select>
    </div>
  </header>
</template>

<script lang="ts" setup>
import Select from '@/components/Inputs/Select.vue';

import { accountDropdownOptions } from './Header.config';
import { AccountOption } from './Header';
import { MenuIcon, useSidebar } from '@pleez-tech/fe-components-library';

import { setupLanguage } from '@/plugins/i18n';
import { useAuthStore } from '@/store/v2';
import { useRouter } from 'vue-router';

const authStore = useAuthStore();

const router = useRouter();

const { toggleSidebar, isMobile } = useSidebar();

const handleAccountOptionClick = (option: AccountOption[]) => {
  const value = option[0]?.value;

  switch (value) {
    case 'logout':
      authStore.logout();
      router.push('/logout');
      break;
    case 'settings':
      router.push('/settings/account');
      break;
    case 'businessSettings':
      router.push('/settings/business');
    default:
      break;
  }
};

const handleToggleSidebar = () => {
  toggleSidebar();
};
</script>

<style lang="scss" scoped>
@import '@pleez-tech/fe-components-library/styles';

.header {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  justify-content: flex-end;

  width: 100%;
  min-height: var(--header-height);

  padding: var(--spacing-s);

  background-color: var(--brand-grey);

  box-sizing: border-box;

  &__mobile-menu-icon {
    margin-right: 16px;
  }
  &__actions {
    display: flex;
    flex-direction: column;

    width: 100%;
  }
  &__option-icon {
    flex-shrink: 0;
    margin-right: 8px;
    stroke: var(--brand-black);
  }
  &__modal {
    position: absolute;
  }
  &__flags {
    display: flex;
    margin: 16px 0 8px 24px;

    & > .fi {
      margin-right: 8px;
      cursor: pointer;

      &:last-child {
        margin-right: 0;
      }
    }
  }
  &__button {
    width: 100%;
    height: 100%;

    &:after {
      position: absolute;
      top: 4px;
      bottom: 4px;
      left: 4px;

      width: calc(46px - 4px); //minus left
      height: calc(100% - 8px); //minus top and bottom

      border-radius: 6px;

      opacity: 0.5;

      content: '';
    }
  }
  &__account-button {
    &:after {
      background-color: #faf5ff;
    }

    .header__action-icon {
      stroke: var(--brand-primary);
    }

    :deep() .vs__selected-options {
      margin-left: calc(var(--input-height) + 8px);
    }
  }
  &__account-option {
    display: flex;
    align-items: center;

    &--small {
      font-size: 0.9rem;
    }
  }

  &__temp-mobile {
    display: flex;
    align-items: center;
    margin-bottom: 1rem;
  }

  @include breakpoint-from('smallDesktop') {
    flex-direction: row;

    padding: 16px 32px;

    &__temp-mobile {
      display: none;
    }

    &__mobile-menu-icon {
      display: none;
    }
    &__actions {
      flex-direction: row;

      width: fit-content;
    }
    &__account-button {
      width: 258px;

      margin-left: 16px;
    }
  }
}
</style>
