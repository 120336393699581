<template>
  <section class="not-authenticated">
    <span class="not-authenticated__padding" />
    <div class="not-authenticated__content">
      <router-view />
    </div>
  </section>
</template>

<script lang="ts" setup>
import { useAuthStore } from '@/store/v2';
import { onMounted } from 'vue';
import { useRouter } from 'vue-router';

const authStore = useAuthStore();

const router = useRouter();

onMounted(() => {
  if (authStore.isLoggedIn()) {
    router.push('/dashboard');
  }
});
</script>

<style lang="scss" scoped>
@import '@pleez-tech/fe-components-library/styles';

.not-authenticated {
  display: flex;
  justify-content: center;

  height: 100%;

  &__padding {
    display: none;

    height: 100vh;
    width: 45%;

    background-color: var(--brand-primary);
  }
  &__content {
    flex: 1;

    height: 100%;
    padding: var(--spacing-xl) var(--spacing-s);

    box-sizing: border-box;
  }

  @include breakpoint-from('tablet') {
    align-items: center;
  }
  @include breakpoint-from('smallDesktop') {
    justify-content: unset;

    &__padding {
      display: block;
    }
  }
}
</style>
