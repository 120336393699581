<template>
  <div class="optimizations-dashboard">
    <transition name="fade" mode="out-in">
      <component
        :class="['optimizations-dashboard__layout']"
        :is="layout"
        v-bind="{ meta: $route.meta }"
      >
        <router-view />
      </component>
    </transition>
  </div>
</template>

<script setup lang="ts">
import { computed, onMounted } from 'vue';
import { debounce } from '@/utils/timers/timers';
import { setupLanguage } from '@/plugins/i18n';
import { useStore } from 'vuex';
import { useRoute } from 'vue-router';
import { useAuthStore } from './store/v2';
import ParentLayout from './layouts/ParentLayout.vue';

const store = useStore();
const route = useRoute();
const authStore = useAuthStore();

onMounted(() => {
  setNewViewPort();
  setupLanguage();

  window.addEventListener('resize', debounce(setNewViewPort));
});

const setNewViewPort = () => {
  store.commit('setNewViewPort', window.innerWidth);
};

const layout = computed(() => route.meta.layout || ParentLayout);

const isAuthenticated = computed(() => authStore.isLoggedIn);
</script>

<style lang="scss">
@import '@pleez-tech/fe-components-library/styles';

.optimizations-dashboard {
  height: 100%;

  &__layout {
    display: flex;

    &--authenticated {
      flex-direction: column;
    }
  }

  @include breakpoint-from('smallDesktop') {
    &__layout {
      height: 100vh;
    }
  }
}

body::-webkit-scrollbar {
  width: 2px; /* width of the entire scrollbar */
  height: 4px;
}
body::-webkit-scrollbar-track {
  background: transparent; /* color of the tracking area */
}
body::-webkit-scrollbar-thumb {
  background-color: #232859; /* color of the scroll thumb */
  border-radius: 20px; /* roundness of the scroll thumb */
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
</style>
