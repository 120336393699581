import { HttpClient } from '@pleez-tech/http-client';
import { defineStore } from 'pinia';
import { ref } from 'vue';
import type { LoginResponse } from './Auth';

export const useAuthStore = defineStore('auth', () => {
  // API Key
  const apiKey = ref<string | null>(localStorage.getItem('api-key'));
  const setAPIKey = (newKey: string) => {
    apiKey.value = newKey;
  };

  // Token
  const token = ref<string | null>(localStorage.getItem('token'));
  const setToken = (newToken: string) => {
    token.value = newToken;
    if (persistent.value) {
      localStorage.setItem('token', newToken);
    }
  };

  // Roles
  const roles = ref<string[]>(localStorage.getItem('roles')?.split(';') ?? []);
  const setRoles = (newRoles: string[]) => {
    roles.value = newRoles;
    if (persistent.value) {
      localStorage.setItem('roles', newRoles.join(';'));
    }
  };

  // Email
  const email = ref<string | null>(localStorage.getItem('email'));

  // Persistent
  const persistent = ref<boolean>(Boolean(localStorage.getItem('persistent')));
  const setPersistent = (newPersistent: boolean) => {
    persistent.value = newPersistent;
    if (newPersistent) {
      localStorage.setItem('persistent', newPersistent.toString());
    } else {
      localStorage.removeItem('persistent');
    }
  };

  // Check if logged in
  const isLoggedIn = () => {
    return Boolean(token.value || apiKey.value);
  };

  // Get auth header for HTTP requests
  const getAuthHeader = () => {
    if (apiKey.value) {
      return `API-KEY ${apiKey.value}`;
    } else if (token.value) {
      return `Bearer ${token.value}`;
    } else {
      return undefined;
    }
  };

  // Login action
  const login = async (emailInput: string, passwordInput: string, persistentFlag = true) => {
    const result = await HttpClient.post<LoginResponse>(
      `${process.env.VUE_APP_API_BASE_URL}/auth`,
      {
        email: emailInput,
        password: passwordInput,
      },
    );

    // Persistance
    persistent.value = persistentFlag;
    localStorage.setItem('persistent', persistentFlag.toString());

    // Roles
    roles.value = result.data.roles;
    if (persistentFlag) {
      localStorage.setItem('roles', result.data.roles.join(';'));
    }

    // Email
    email.value = emailInput;
    if (persistentFlag) {
      localStorage.setItem('email', emailInput);
    }

    // Token
    setToken(result.data.accessToken);
  };

  // Logout action
  const logout = () => {
    // Clear all values and remove from local storage
    apiKey.value = null;
    token.value = null;
    persistent.value = false;
    roles.value = [];
    email.value = null;
    localStorage.removeItem('api-key');
    localStorage.removeItem('token');
    localStorage.removeItem('persistent');
    localStorage.removeItem('roles');
    localStorage.removeItem('email');
  };

  return {
    apiKey,
    token,
    roles,
    email,
    getAuthHeader,
    isLoggedIn,
    setAPIKey,
    setToken,
    setRoles,
    setPersistent,
    login,
    logout,
  };
});
