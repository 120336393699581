import { ProfileIcon, CogIcon, LogoutIcon } from '@pleez-tech/fe-components-library';

export const accountDropdownOptions = [
  {
    label: 'shared.navbar.settings',
    value: 'settings',
    icon: ProfileIcon,
  },
  {
    label: 'shared.navbar.businessSettings',
    value: 'businessSettings',
    icon: CogIcon,
  },
  {
    label: 'shared.navbar.logout',
    value: 'logout',
    icon: LogoutIcon,
  },
];
