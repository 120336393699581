export enum RouteRoot {
  // Sidebar routes
  Home = 'home',
  Actions = 'actions',
  Market = 'market',
  Analytics = 'analytics',

  // Others
  Settings = 'settings',
}
