import { getCurrentInstance } from 'vue';
import { SideBarRoute } from '@/components/Sidebar/Sidebar';
import { RouteMeta } from 'vue-router';
import { useAuthStore } from '@/store/v2';

export const isLockedByRole = (link: SideBarRoute | RouteMeta): boolean | boolean[] | undefined => {
  const lockedRoles = link.hiddenLinkRoles as string[];
  if (!lockedRoles) return false;
  const authStore = useAuthStore();
  const routeBlockedByRole = lockedRoles.some((role) => authStore.roles.includes(role));
  return routeBlockedByRole;
};

export const isLockedForZonesoft = (link: SideBarRoute | RouteMeta): boolean => {
  const routeBlockedForZonesoft = Boolean(link.isZonesoftHidden && isZonesoftUser());
  return routeBlockedForZonesoft;
};

export const isZonesoftUser = (): boolean => {
  const authStore = useAuthStore();
  return Boolean(authStore.apiKey);
};

export const userHasAnyMatchingRole = (roles: string[]) =>
  useAuthStore().roles.some((r) => roles.includes(r));
