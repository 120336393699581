/**
 * Here is defined all routes of the application with their respective layouts & views.
 * And exported to be used at the router.js file.
 * Example:
 *  {
 *     path: '/',
 *     name: 'home',
 *     component: Views.Home,
 *     meta: {
 *        layout: ParentLayout
 *     }
 *  }
 */

import {
  AwardIcon,
  CalendarIcon,
  ChartUpIcon,
  CoinPileIcon,
  LightbulbIcon,
  PercentageIcon,
  TargetIcon,
  CoinIcon,
  ReportIcon,
  SquaresIcon,
  MenuScrollIcon,
  PleezIcon,
  SettingsIcon,
  HomeIcon,
} from '@pleez-tech/fe-components-library';
import type { Route } from './routesInterface';

import AuthenticatedLayout from '@/layouts/AuthenticatedLayout.vue';
import NotAuthenticatedLayout from '@/layouts/NotAuthenticatedLayout.vue';
import { RouteRoot } from './routes.model';

const HomePage = () => import('@/views/home/HomePage.vue');
const HomePageV2 = () => import('@/views/home/v2/HomePageView.vue');
const EventsPage = () => import('@/views/actions/insights/events/EventsPage.vue');

const CompetitorRankingView = () => import('@/views/market/ranking/CompetitorRankingView.vue');
const MarketInsightsView = () => import('@/views/market/trends/MarketInsightsView.vue');

const CompetitorTrackerView = () =>
  import('@/views/market/competitorTracker/CompetitorTrackerView.vue');
const PromotionsView = () => import('@/views/market/promotions/PromotionsView.vue');
const PriceComparisonView = () => import('@/views/market/priceComparison/PriceComparisonView.vue');

const MenuComparisonView = () => import('@/views/actions/menuComparison/MenuComparisonView.vue');
const MenuChangesView = () => import('@/views/actions/menuChanges/MenuChangesView.vue');

const PleezInsightsView = () => import('@/views/actions/insights/all/PleezInsightsView.vue');
const InsightsManagementView = () =>
  import('@/views/actions/insights/management/InsightsManagementView.vue');
const SmartStrategiesView = () => import('@/views/actions/smartStrategies/SmartStrategiesView.vue');
const PromotionsScheduleView = () =>
  import('@/views/actions/promotions/schedule/PromotionsScheduleView.vue');

const OverviewView = () => import('@/views/analytics/overview/OverviewView.vue');
const DashboardView = () => import('@/views/analytics/sales/SalesView.vue');
const ReportsJobView = () => import('@/views/analytics/reports/ReportsJobView.vue');
const ReportsManagementView = () =>
  import('@/views/analytics/reports/reportJobManagement/ReportJobManagementView.vue');
const PromotionsAnalysisView = () =>
  import('@/views/analytics/promotions/analysis/PromotionsAnalysisView.vue');
const PromotionsDetailsView = () =>
  import('@/views/analytics/promotions/details/PromotionsDetailsView.vue');

const SettingsAccountView = () => import('@/views/settings/account/SettingsAccountView.vue');
const SettingsPaymentsView = () => import('@/views/settings/payments/SettingsPaymentsView.vue');
const SettingsBusinessView = () => import('@/views/settings/business/SettingsBusinessView.vue');

const LoginView = () => import('@/views/login/LoginView.vue');
const LogoutView = () => import('@/views/logout/LogoutView.vue');
const ForgotPasswordView = () => import('@/views/forgotPassword/ForgotPasswordView.vue');
const NotFoundView = () => import('@/views/notFound/NotFoundView.vue');

const ParentLayout = () => import('@/layouts/ParentLayout.vue');

export const ROUTES: Route[] = [
  // =============================================================================
  // HOME
  // =============================================================================
  /**
   * This can be done with guards. When/if the reload on
   * platform switch is removed, change this to guard
   */
  {
    path: '/home',
    component: HomePageV2,
    title: 'sidebar.dashboard.label',
    icon: HomeIcon,
    meta: {
      title: 'sidebar.home.label',
      root: RouteRoot.Home,
      layout: AuthenticatedLayout,
      icon: 'HomeIcon',
      isSuperUserLocked: false,
      singlePage: true,
    },
  },
  {
    path: '/v2/home',
    component: HomePage,
    title: 'sidebar.dashboard.label',
    icon: HomeIcon,
    meta: {
      layout: AuthenticatedLayout,
      isSuperUserLocked: false,
      singlePage: true,
    },
  },

  // =============================================================================
  // ANALYTICS
  // =============================================================================

  // Overview
  {
    path: '/analytics/overview',
    component: OverviewView,
    icon: TargetIcon,
    meta: {
      title: 'sidebar.analytics.overview',
      icon: TargetIcon,
      isSuperUserLocked: false,
      isZonesoftLocked: true,
      root: RouteRoot.Analytics,
      layout: AuthenticatedLayout,
      breadcrumbs: {
        page: 'sidebar.analytics.label',
        subPage: ['sidebar.analytics.overview'],
      },
      allowedRoles: ['sales'],
    },
  },

  // Promotion Analytics
  {
    path: '/analytics/promotions',
    redirect: '/analytics/promotions/analysis',
    meta: { layout: ParentLayout },
  },
  {
    path: '/analytics/promotions/analysis',
    component: PromotionsAnalysisView,
    meta: {
      title: 'analytics.promotions.analysis.title',
      icon: CoinIcon,
      isSuperUserLocked: false,
      root: RouteRoot.Analytics,
      layout: AuthenticatedLayout,
      breadcrumbs: {
        page: 'sidebar.analytics.label',
        subPage: ['analytics.promotions.analysis.title'],
        activeSubPageIndex: 0,
        subPagesIdentifier: ['promotions/analysis'],
      },
      allowedRoles: ['sales'],
    },
  },
  {
    path: '/analytics/promotions/details/:id',
    component: PromotionsDetailsView,
    meta: {
      title: 'sidebar.analytics.promotions',
      icon: CoinIcon,
      isSuperUserLocked: false,
      root: RouteRoot.Analytics,
      layout: AuthenticatedLayout,
      breadcrumbs: {
        page: 'sidebar.analytics.label',
        subPage: ['analytics.promotions.analysis.title'],
        activeSubPageIndex: 0,
        subPagesIdentifier: ['promotions/analysis'],
      },
      allowedRoles: ['sales'],
      hidden: true,
    },
  },

  // Sales
  {
    path: '/dashboard',
    redirect: '/analytics/sales',
    meta: { layout: ParentLayout },
  },
  {
    path: '/analytics/sales',
    component: DashboardView,
    meta: {
      title: 'sidebar.analytics.sales',
      icon: CoinIcon,
      isSuperUserLocked: false,
      isZonesoftLocked: true,
      root: RouteRoot.Analytics,
      layout: AuthenticatedLayout,
      breadcrumbs: {
        page: 'sidebar.analytics.label',
        subPage: ['sidebar.analytics.sales'],
      },
      allowedRoles: ['sales'],
    },
  },

  // Reports
  {
    path: '/reports',
    redirect: '/analytics/reports',
    meta: { layout: ParentLayout },
  },
  {
    path: '/analytics/reports',
    component: ReportsJobView,
    meta: {
      title: 'sidebar.analytics.reporting',
      icon: ReportIcon,
      isSuperUserLocked: false,
      root: RouteRoot.Analytics,
      layout: AuthenticatedLayout,
      breadcrumbs: {
        page: 'sidebar.analytics.label',
        subPage: ['sidebar.analytics.reporting'],
        activeSubPageIndex: 0,
        subPagesIdentifier: ['reports'],
      },
      allowedRoles: ['sales'],
    },
  },
  {
    path: '/analytics/reports/management/:jobId',
    component: ReportsManagementView,
    meta: {
      title: 'sidebar.analytics.reportsJobs',
      icon: 'ReportsJobIcon',
      isSuperUserLocked: false,
      root: RouteRoot.Analytics,
      layout: AuthenticatedLayout,
      breadcrumbs: {
        page: 'sidebar.analytics.label',
        subPage: ['sidebar.analytics.reporting', 'sidebar.analytics.reportsManagement'],
        activeSubPageIndex: 1,
        subPagesIdentifier: ['reports', 'reports/management/:jobId'],
      },
      allowedRoles: ['sales'],
      hidden: true,
    },
  },

  // =============================================================================
  // ACTIONS
  // =============================================================================

  // Recommendations
  {
    path: '/actions/insights',
    component: ParentLayout,
    children: [
      {
        path: 'all',
        meta: {
          title: 'sidebar.actions.insights.allInsights',
          root: RouteRoot.Actions,
          breadcrumbs: {
            page: 'sidebar.actions.insights.allInsights',
            subPage: [
              'sidebar.actions.insights.allInsights',
              'sidebar.actions.insights.eventInsights',
              'sidebar.actions.insights.insightsManagement',
            ],
            activeSubPageIndex: 0,
            subPagesIdentifier: ['insights/all', 'insights/events', 'insights/management'],
          },
          layout: AuthenticatedLayout,
        },
        component: PleezInsightsView,
      },
      {
        path: 'events',
        meta: {
          title: 'sidebar.actions.insights.eventInsights',
          root: RouteRoot.Actions,
          breadcrumbs: {
            page: 'sidebar.actions.insights.eventInsights',
            subPage: [
              'sidebar.actions.insights.allInsights',
              'sidebar.actions.insights.eventInsights',
              'sidebar.actions.insights.insightsManagement',
            ],
            activeSubPageIndex: 1,
            subPagesIdentifier: ['insights/all', 'insights/events', 'insights/management'],
          },
          layout: AuthenticatedLayout,
          singlePage: true,
        },
        component: EventsPage,
      },
      {
        path: 'management',
        meta: {
          title: 'sidebar.actions.insights.insightsManagement',
          root: RouteRoot.Actions,
          breadcrumbs: {
            page: 'sidebar.actions.insights.insightsManagement',
            subPage: [
              'sidebar.actions.insights.allInsights',
              'sidebar.actions.insights.eventInsights',
              'sidebar.actions.insights.insightsManagement',
            ],
            activeSubPageIndex: 2,
            subPagesIdentifier: ['insights/all', 'insights/events', 'insights/management'],
          },
          layout: AuthenticatedLayout,
        },
        component: InsightsManagementView,
      },
    ],
    meta: {
      title: 'sidebar.actions.insights.label',
      icon: PleezIcon,
      isSuperUserLocked: false,
      root: RouteRoot.Actions,
      layout: ParentLayout,
    },
  },

  // Smart strategies
  {
    path: '/actions/smart-strategies',
    component: SmartStrategiesView,
    icon: PleezIcon,
    meta: {
      title: 'sidebar.actions.smartStrategies',
      icon: LightbulbIcon,
      isSuperUserLocked: false,
      requiresFlag: 'tech.salesboost.strategies.page',
      layout: AuthenticatedLayout,
      root: RouteRoot.Actions,
      breadcrumbs: {
        page: 'sidebar.actions.label',
        subPage: ['sidebar.actions.smartStrategies'],
      },
    },
  },

  // Promotions Schedule
  {
    path: '/actions/promotions/schedule',
    component: PromotionsScheduleView,
    meta: {
      title: 'sidebar.actions.promotions.schedule.label',
      icon: CalendarIcon,
      isSuperUserLocked: false,
      requiresFlag: 'tech.salesboost.strategies.page',
      layout: AuthenticatedLayout,
      root: RouteRoot.Actions,
      breadcrumbs: {
        page: 'sidebar.actions.label',
        subPage: ['sidebar.actions.promotions.schedule.label'],
      },
    },
  },

  // Menu Changes
  {
    path: '/menu-changes',
    redirect: '/actions/menu-changes',
    meta: { layout: ParentLayout },
  },
  {
    path: '/actions/menu-changes',
    component: MenuChangesView,
    meta: {
      title: 'sidebar.actions.menuChanges',
      icon: SquaresIcon,
      isSuperUserLocked: false,
      layout: AuthenticatedLayout,
      root: RouteRoot.Actions,
      breadcrumbs: {
        page: 'sidebar.actions.label',
        subPage: ['sidebar.actions.menuChanges'],
      },
    },
  },

  // Menu Comparison
  {
    path: '/management/menu-comparison',
    component: MenuComparisonView,
    meta: {
      title: 'sidebar.actions.menuComparison',
      icon: MenuScrollIcon,
      isSuperUserLocked: false,
      root: RouteRoot.Actions,
      layout: AuthenticatedLayout,
      breadcrumbs: {
        page: 'sidebar.actions.label',
        subPage: ['sidebar.actions.menuComparison'],
      },
    },
  },

  // =============================================================================
  // MARKET
  // =============================================================================

  // Competitor Tracker
  {
    path: '/market/tracker',
    component: CompetitorTrackerView,
    icon: TargetIcon,
    meta: {
      title: 'sidebar.competitors.tracker',
      icon: TargetIcon,
      isSuperUserLocked: false,
      layout: AuthenticatedLayout,
      root: RouteRoot.Market,
      breadcrumbs: {
        page: 'sidebar.competitors.label',
        subPage: ['sidebar.competitors.tracker'],
      },
      singlePage: true,
    },
  },

  // Price Comparison
  {
    path: '/market/price-comparison',
    component: PriceComparisonView,
    meta: {
      title: 'sidebar.competitors.priceComparison',
      icon: CoinPileIcon,
      isSuperUserLocked: false,
      layout: AuthenticatedLayout,
      root: RouteRoot.Market,
      breadcrumbs: {
        page: 'sidebar.competitors.label',
        subPage: ['sidebar.competitors.priceComparison'],
      },
    },
  },

  // Competitor Promotions
  {
    path: '/market/promotions',
    component: PromotionsView,
    meta: {
      title: 'sidebar.competitors.promotions',
      icon: PercentageIcon,
      isSuperUserLocked: false,
      layout: AuthenticatedLayout,
      root: RouteRoot.Market,
      breadcrumbs: {
        page: 'sidebar.competitors.label',
        subPage: ['sidebar.competitors.promotions'],
      },
    },
  },

  // Restaurant Ranking
  {
    path: '/market/ranking',
    component: CompetitorRankingView,
    meta: {
      title: 'sidebar.market.competitorRanking',
      icon: AwardIcon,
      isSuperUserLocked: false,
      lockedRoles: ['csm'],
      root: RouteRoot.Market,
      layout: AuthenticatedLayout,
      breadcrumbs: {
        page: 'sidebar.market.label',
        subPage: ['sidebar.market.competitorRanking'],
      },
    },
  },

  // Market Trends
  {
    path: '/market-insights',
    redirect: '/market/trends',
    meta: { layout: ParentLayout, lockedRoles: ['csm'] },
  },
  {
    path: '/market/trends',
    component: MarketInsightsView,
    icon: ChartUpIcon,
    meta: {
      title: 'sidebar.market.marketTrends',
      icon: ChartUpIcon,
      isSuperUserLocked: false,
      lockedRoles: ['csm'],
      root: RouteRoot.Market,
      layout: AuthenticatedLayout,
      breadcrumbs: {
        page: 'sidebar.market.label',
        subPage: ['sidebar.market.marketTrends'],
      },
    },
  },

  // =============================================================================
  // SETTINGS
  // =============================================================================
  {
    path: '/settings/account',
    component: SettingsAccountView,
    meta: {
      title: 'sidebar.settings.account',
      icon: SettingsIcon,
      isSuperUserLocked: [false, false, false],
      root: RouteRoot.Settings,
      layout: AuthenticatedLayout,
      breadcrumbs: {
        page: 'sidebar.settings.label',
        subPage: [
          'sidebar.settings.account',
          'sidebar.settings.payments',
          'sidebar.settings.business',
        ],
        activeSubPageIndex: 0,
        subPagesIdentifier: ['account', 'payments', 'business'],
      },
    },
  },
  {
    path: '/settings/payments',
    component: SettingsPaymentsView,
    meta: {
      title: 'sidebar.settings.account',
      icon: SettingsIcon,
      isSuperUserLocked: [false, false, false],
      root: RouteRoot.Settings,
      layout: AuthenticatedLayout,
      breadcrumbs: {
        page: 'sidebar.settings.label',
        subPage: [
          'sidebar.settings.account',
          'sidebar.settings.payments',
          'sidebar.settings.business',
        ],
        activeSubPageIndex: 1,
        subPagesIdentifier: ['account', 'payments', 'business'],
      },
    },
  },
  {
    path: '/settings/business',
    component: SettingsBusinessView,
    meta: {
      title: 'sidebar.settings.business',
      icon: SettingsIcon,
      isSuperUserLocked: [false, false, false],
      root: RouteRoot.Settings,
      layout: AuthenticatedLayout,
      breadcrumbs: {
        page: 'sidebar.settings.label',
        subPage: [
          'sidebar.settings.account',
          'sidebar.settings.payments',
          'sidebar.settings.business',
        ],
        activeSubPageIndex: 2,
        subPagesIdentifier: ['account', 'payments', 'business'],
      },
    },
  },

  // =============================================================================
  // OTHERS
  // =============================================================================

  {
    path: '/',
    component: LoginView,
    meta: {
      layout: NotAuthenticatedLayout,
    },
  },
  {
    path: '/forgot-password',
    component: ForgotPasswordView,
    meta: {
      layout: NotAuthenticatedLayout,
    },
  },
  {
    path: '/logout',
    component: LogoutView,
    meta: {},
  },

  // Default route
  {
    path: '/:pathMatch(.*)*',
    component: NotFoundView,
    meta: {
      layout: AuthenticatedLayout,
    },
  },
];
