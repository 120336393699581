import { SideBarRoute } from '@/components/Sidebar/Sidebar';
import { RouteMeta } from 'vue-router';

export const isSuperUserLocked = (
  link: SideBarRoute | RouteMeta,
): boolean | boolean[] | undefined => {
  // If is an array, must have activeSubPageIndex, means is a subpage inside a page with a tab
  if (
    typeof link.isSuperUserHidden == 'object' &&
    link?.breadcrumbs?.activeSubPageIndex != undefined
  ) {
    return link.isSuperUserHidden[link?.breadcrumbs.activeSubPageIndex];
  }
  return link?.isSuperUserHidden;
};

export const isSuperUser = (): boolean => {
  return false;
};
