<template>
  <div class="checkbox">
    <input
      class="checkbox__input"
      type="checkbox"
      v-model="checkboxValue"
      :disabled="isDisabled"
      :id="cid"
      @click="
        (ev: Event) => {
          // To avoid double triggering change events
          //  (for example, when dynamically changing the checkbox value like we do in the insights management page)
          //  we only trigger the change event when the checkbox is actually clicked.
          $emit('change', (ev.target! as HTMLInputElement).checked);
        }
      "
    />
    <label v-if="label" :for="cid">
      {{ label }}
    </label>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
  emits: {
    change: (_state: boolean) => true,
  },
  props: {
    label: {
      type: String,
      required: false,
    },
    isChecked: {
      type: Boolean,
      required: false,
    },
    isDisabled: {
      type: Boolean,
      required: false,
    },
    cid: {
      type: String,
      required: false,
    },
    value: {
      type: [Object, String],
      required: false,
      default: () => null,
    },
  },
  data() {
    return {
      checkboxValue: this.isChecked,
    };
  },
  watch: {
    isChecked() {
      this.checkboxValue = this.isChecked;
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@pleez-tech/fe-components-library/styles';

.checkbox {
  @include brand-font-xm;

  --checkbox-width: var(--spacing-s);
  --animation-time: 120ms;

  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: var(--spacing-xxs);

  &__input {
    display: grid;
    place-content: center;

    margin: 0;
    width: var(--checkbox-width);
    aspect-ratio: 1;

    color: var(--brand-primary);
    /* For iOS < 15 to remove gradient background */
    background-color: #fff;

    border: 2px solid var(--brand-black);
    border-radius: var(--spacing-3xs);

    appearance: none;

    font: inherit;

    &:before {
      width: 0.95em;
      aspect-ratio: 1;
      background-color: var(--brand-primary);

      transition: transform var(--animation-time) ease-in-out;

      transform: scale(0);
      transform-origin: bottom left;

      // Checkmark
      clip-path: var(--checkmark-clip-path);

      content: '';
    }
    &:checked:before {
      transform: scale(1);
    }
    &:checked:disabled:before {
      background-color: var(--color-grey);

      cursor: not-allowed;
    }
    &:focus-visible {
      outline: 1px solid var(--brand-primary);
      outline-offset: 1px;
    }
    &:disabled {
      color: var(--color-grey);
      border-color: var(--color-grey);

      // cursor: not-allowed;
    }
  }
}
</style>
