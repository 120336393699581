<template>
  <div>
    <TermsAndConditionsComponent
      v-if="showTermsAndConditionModal"
      :terms-and-conditions="termsAndConditions"
      :is-accepted="isTermsAccepted"
      @closeClick="handleCloseClick"
      @termsAgreed="handleTermsAgreed"
    />
    <template v-if="isTermsAccepted">
      <Sidebar :navigation="navigation" :logo="PleezLogo">
        <Header />
        <main class="content">
          <div
            class="content__body"
            :class="{
              'content__body--single-page': meta?.singlePage,
            }"
          >
            <Breadcrumb class="content__bread-crumbs" />
            <Suspense>
              <router-view class="content__authenticated-layout" />
            </Suspense>
            <Footer
              class="content__footer"
              @termsAndConditionsClick="showTermsAndConditionModal = true"
            />
          </div>
        </main>
      </Sidebar>
    </template>
  </div>
</template>

<script setup lang="ts">
import { onBeforeMount, onMounted, computed, ref, watch } from 'vue';
import Header from '@/components/Header/HeaderComponent.vue';
import Footer from '@/components/Footer/FooterComponent.vue';
import Breadcrumb from '@/components/BreadCrumb/BreadCrumbComponent.vue';
import TermsAndConditionsComponent from '@/components/TermsAndConditions/TermsAndConditionsComponent.vue';
import { NavGroup, Sidebar, PleezLogo } from '@pleez-tech/fe-components-library';
import { ROUTES } from '@/routes/routes';
import {
  useAuthStore,
  useFeatureFlagsStore,
  usePlatformsStore,
  useRestaurantsStore,
} from '@/store/v2';
import { Route } from '@/routes/routesInterface';
import { useRouter } from 'vue-router';
import { useResize } from '@/mixins/resize.composable';
import { useStore } from 'vuex';
import { SideBarRoute } from '@/components/Sidebar/Sidebar';
import { localizeTemplate as $t } from '@/plugins/i18n';
import { canViewLink, routeIsDisabled, SIDEBAR_GROUPS } from './authenticated.config';
import { RouteRoot } from '@/routes/routes.model';

const resize = useResize();
const store = useStore();
const router = useRouter();

defineProps<{
  meta: Route['meta'];
}>();

const isSidebarVisible = ref(false);
const showTermsAndConditionModal = ref(false);
const termsAndConditions = ref();
const isTermsAccepted = computed(() => Boolean(termsAndConditions.value?.acceptedTerms));

const navigation = computed<NavGroup[]>(() => {
  const mainRoots: { [key in RouteRoot]?: SideBarRoute[] } = SIDEBAR_GROUPS.map((g) => ({
    ...g,
    children: ROUTES.filter((r) => r.meta.root === g.root),
  })).reduce(
    (acc, group) => {
      acc[group.root] = group.children.map((c) => ({
        url: c.path,
        ...c.meta,
        rootTitle: c.title,
        rootIcon: group.icon,
        children: c.children,
      }));

      return acc;
    },
    {} as { [key in RouteRoot]?: SideBarRoute[] },
  );

  // Map the grouped roots to the sidebar format
  return Object.keys(mainRoots).map((rootKey) => {
    const links = mainRoots[rootKey as RouteRoot];

    // Check if the root has only one link
    const isSingle = links?.length === 1;

    // Filter links by rules
    const routesFiltredByRules = links?.filter(canViewLink);

    // Map the links
    const items = routesFiltredByRules?.map((item) => {
      // Check if the root has only one link
      const itemFirstChildrenPath = `/${item.root}/${item.children?.[0].meta?.breadcrumbs?.subPagesIdentifier?.[0]}`;

      // Map the sub pages
      const subItems = item.children?.map((child, index) => {
        // This is for the breadcrumbs tabs system
        const routeChild = `${item.root}/${child.meta?.breadcrumbs?.subPagesIdentifier?.[index]}`;
        return {
          title: $t(child?.meta?.title as string),
          url: `/${routeChild}`,
        };
      });

      return {
        title: $t(item.title as string),
        url: item.children?.length ? itemFirstChildrenPath : item.url,
        items: subItems,
        disabled: routeIsDisabled(item),
      };
    });

    const rootTitle = isSingle ? $t(links[0].rootTitle as string) : $t(`sidebar.${rootKey}.label`);

    return {
      title: rootTitle,
      icon: links?.[0].rootIcon,
      items,
    };
  });
});

const handleCloseClick = () => {
  showTermsAndConditionModal.value = false;
};

const handleTermsAgreed = () => {
  showTermsAndConditionModal.value = false;
  termsAndConditions.value.acceptedTerms = true;
};

onBeforeMount(() => {
  const authStore = useAuthStore();
  if (!authStore.isLoggedIn()) {
    router.push('/');
  }
});

onMounted(async () => {
  termsAndConditions.value = await store.dispatch('getTermsAndConditions');

  const featureFlagStore = useFeatureFlagsStore();
  await featureFlagStore.fetchFeatureFlags();

  const platformsStore = usePlatformsStore();
  await platformsStore.fetchPlatforms();

  const restaurantStore = useRestaurantsStore();
  await restaurantStore.fetchUserRestaurants();

  if (!termsAndConditions.value) {
    throw new Error('Could not get terms and conditions.');
  }

  if (termsAndConditions.value.acceptedTerms) {
    return;
  }

  showTermsAndConditionModal.value = true;
});

watch(
  () => resize.currentViewPort,
  () => {
    isSidebarVisible.value = false;
  },
);
</script>

<style lang="scss" scoped>
@import '@pleez-tech/fe-components-library/styles';

.content {
  --max-content-size: 2000px;
}

.content {
  display: flex;
  flex-direction: column;

  &__body {
    flex: 1;

    display: flex;
    flex-direction: column;
    align-items: center;
    gap: var(--spacing-m);

    padding: var(--spacing-s);
    padding-top: 0;

    background-color: var(--color-white);

    &--single-page {
      @include breakpoint-to('tablet') {
        height: unset;
      }
    }
  }
  &__authenticated-layout,
  &__bread-crumbs {
    flex: 0;
    width: 100%;
    max-width: var(--max-content-size);
  }
  &__authenticated-layout {
    flex: 1;
    width: 100%;
    margin: 0 auto;

    &--single-page {
      max-height: 100%;

      @include breakpoint-to('tablet') {
        max-height: unset;
      }
    }
  }
  &__footer {
    flex: 0;
    width: 100%;
    margin-top: auto;
  }

  @include breakpoint-from('smallDesktop') {
    flex-direction: row;
    flex: 1;

    overflow: auto;

    &__body {
      overflow-y: auto;
      overflow-x: hidden;
    }
  }
}
</style>
