import { GetInsightsOptions, GetMenuChangesOptions } from './endpoints.types';
import { SimplifiedFilter } from '@/utils/filters';
import type {
  PriceMetricsCompetitorsFilter,
  PriceMetricsRegionFilter,
} from '@/views/market/priceComparison/priceMetrics/PriceMetrics';
import { CompetitorRankingFilters } from '@/views/market/ranking/_competitorPosition';

export const AUTH = '/auth';

export const RESTAURANTS_USER = '/restaurants/user';

export const filtersToQueryParams = (_filter: SimplifiedFilter | undefined) => {
  if (!_filter) {
    return '';
  }

  // Build filter
  const mappedFilters = Object.entries(_filter)
    .filter((entry) => entry[1]?.length > 0)
    .map((entry) => `${entry[0]}:${entry[1].map((value) => encodeURIComponent(value)).join(',')}`);

  const filter = mappedFilters.join(';');

  const params = filter ? `filter=${filter}` : '';

  return params;
};

export const PRICE_COMPARISON = {
  ROOT: (searchParams: URLSearchParams) => {
    return `/priceComparison?${searchParams}`;
  },
  FILTERS: '/priceComparison/filters/competitorsMap',
  MORE_FILTERS: (filters?: SimplifiedFilter) => {
    return `/priceComparison/filters/competitorsMap/more?${filtersToQueryParams(filters)}`;
  },
  SUGGESTED_COMPETITORS: (filters?: SimplifiedFilter) => {
    return `/competitors?${filtersToQueryParams(filters)}`;
  },
  SUGGESTED_COMPETITORS_SAVED: (filters?: SimplifiedFilter) => {
    return `/competitors/saved?${filtersToQueryParams(filters)}`;
  },
  CATEGORIES: (filters?: SimplifiedFilter) => {
    return `/priceComparison/filters/priceComparison/categories?${filtersToQueryParams(filters)}`;
  },
  ITEMS: (filters?: SimplifiedFilter) => {
    return `/priceComparison/filters/priceComparison/items?${filtersToQueryParams(filters)}`;
  },
  PRICE_METRICS_REGION: (filters: PriceMetricsRegionFilter) => {
    let url = `/priceComparison/priceMetrics/region?`;

    // Add radius to query
    url += `radius=${filters.radius}`;

    // Add restaurant ID to query
    url += `&idRestaurant=${filters.idRestaurant}`;

    // If categories, add to query
    if (filters.categories?.length) {
      const categories = filters.categories.map((cat) => encodeURIComponent(cat));
      url += `&categories=${categories.join(',')}`;
    }

    // If item IDs, add to query
    if (filters.idItems?.length) {
      url += `&idItems=${filters.idItems.join(',')}`;
    }

    if (filters.restaurantType?.length) {
      url += `&restaurantType=${filters.restaurantType.join(',')}`;
    }

    // Add idSource to query
    url += `&idSource=${filters.platformId}`;

    return url;
  },
  PRICE_METRICS_COMPETITORS: (filters: PriceMetricsCompetitorsFilter) => {
    let url = `/priceComparison/priceMetrics/competitors?`;

    // Add idRestaurants to query
    url += `idRestaurants=${filters.idRestaurants.join(',')}`;

    // If categories, add to query
    if (filters.categories.length) {
      const categories = filters.categories.map((cat) => encodeURIComponent(cat));
      url += `&categories=${categories.join(',')}`;
    }

    // If item IDs, add to query
    if (filters.idItems.length) {
      url += `&idItems=${filters.idItems.join(',')}`;
    }

    // Add idSource to query
    url += `&idSource=${filters.platformId}`;

    return url;
  },
  COMPETITOR_RANKING: (filters: CompetitorRankingFilters) => {
    const params = new URLSearchParams();
    params.append('lat', filters.location.lat.toString());
    params.append('long', filters.location.lng.toString());
    params.append('platformId', filters.platform?.id.toString()!);
    params.append('query', filters.restaurantType);
    return `/v2/ranking/restaurant?${params.toString()}`;
  },
  TAGS: (filters: { platformId: number }) =>
    `/v2/tag?platformId=${filters.platformId}&forUser=true`,
};

export const HOME = '/home';

export const RESTAURANTS_PLATFORMS = '/users/restaurantsAndPlatforms';
export const CITIES_PLATFORMS = '/users/citiesAndPlatforms';

export const FUNCTIONS = {
  LIST_USER_FUNCTIONS: (options: GetMenuChangesOptions) => {
    const { filter: filter, sort, order, limit, offset } = options;

    const params = [`order=${order}`];

    params.push(filtersToQueryParams(filter));

    if (sort) {
      params.push(`sort=${sort}`);
    }

    if (offset) {
      params.push(`offset=${offset}`);
    }

    if (limit) {
      params.push(`limit=${limit}`);
    }

    return `/functions/listUserFunctions?${params.join('&')}`;
  },
};

export const INSIGHTS = {
  ROOT: (options: GetInsightsOptions) => {
    const { filter: filter, sort, order, limit, offset } = options;

    const params = [`order=${order}`];
    if (typeof filter !== 'string') {
      params.push(filtersToQueryParams(filter));
    } else if (filter) {
      params.push(`filter=${filter}`);
    }

    if (sort) {
      params.push(`sort=${sort}`);
    }

    if (offset) {
      params.push(`offset=${offset}`);
    }

    if (limit) {
      params.push(`limit=${limit}`);
    }

    return `/insights?${params.join('&')}`;
  },
  TYPES: '/insights/types',
  SETTINGS: '/insights/settings',
};

export const MARKET = {
  PROMOTIONS_TYPES: '/promotions/types',
  PLATFORMS: '/platforms',
};
