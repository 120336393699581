import { mobileMaxWidth, smallDesktopMinWidth, tabletMaxWidth, viewPort } from '@/utils/constants';
import { computed, ref, watch } from 'vue';

import { store } from '@/store/v1';

export function useResize() {
  const currentViewPortWidth = ref(0);

  const currentViewPort = computed(() => {
    return currentViewPortWidth.value < mobileMaxWidth
      ? viewPort.Mobile
      : currentViewPortWidth.value < tabletMaxWidth
        ? viewPort.Tablet
        : currentViewPortWidth.value < smallDesktopMinWidth
          ? viewPort.SmallDesktop
          : viewPort.Desktop;
  });

  const isMobileViewPort = computed(() => currentViewPort.value === viewPort.Mobile);

  const isTabletViewPort = computed(() => currentViewPort.value === viewPort.Tablet);

  watch(
    () => store.getters.currentViewPort,
    (newCurrentViewPort, oldCurrentViewPort) => {
      if (newCurrentViewPort !== oldCurrentViewPort) {
        currentViewPortWidth.value = newCurrentViewPort;
      }
    },
    { immediate: true },
  );

  return {
    currentViewPort,
    isMobileViewPort,
    isTabletViewPort,
  };
}
