<template>
  <footer class="footer">
    <p class="footer__text">
      pleez {{ currentYear }} -
      <button class="footer__terms-and-conditions" @click="handleOpenTermsAndConditions">
        Terms & Conditions
      </button>
    </p>
    <!--
    <PleezLogo class="footer__logo" />
    <ul class="footer__social-media">
      <li v-for="item in socialMedia">
        <a class="footer__button" :href="item.url">
          <component :is="item.icon" />
        </a>
      </li>
    </ul>
    -->
  </footer>
</template>

<script setup lang="ts">
import { socialMedia } from './Footer.config';

const emits = defineEmits<{
  termsAndConditionsClick: [];
}>();

const handleOpenTermsAndConditions = () => {
  emits('termsAndConditionsClick');
};

// Always get current year
const currentYear = new Date().getFullYear();
</script>

<style scoped lang="scss">
@import '@pleez-tech/fe-components-library/styles';

.footer {
  @include brand-font-m;

  display: grid;
  grid-template-areas: 'logo' 'social-media' 'text';
  justify-items: center;
  align-items: center;

  gap: var(--spacing-xs);

  &__logo {
    grid-area: logo;
  }
  &__text {
    grid-area: text;
  }
  &__terms-and-conditions {
    text-decoration: underline;
    cursor: pointer;
  }
  &__social-media {
    display: flex;
    grid-area: social-media;

    gap: var(--spacing-xs);
  }
  &__button {
    display: flex;
    justify-content: center;
    align-items: center;

    width: var(--spacing-m);
    aspect-ratio: 1;

    border-radius: var(--spacing-3xs);

    background-color: var(--brand-black);
  }

  @include breakpoint-from('tablet') {
    grid-template-areas: 'text logo social-media';

    &__text {
      justify-self: start;
    }
    &__social-media {
      justify-self: flex-end;
    }
  }
}
</style>
